
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { Route, Switch } from "react-router-dom";
import routes from "../layout/routes";

const Dashboard = ({ dashboard, match }) => {

    useEffect(() => {
        document.body.classList.add('bg-info')

        return () => {
            document.body.classList.remove('bg-info')
        }
    }, [])

    return (
        <div className="layout-auth" >
            <Switch>
                {routes.map((prop, key) => {

                    return (
                        <Route
                            exact path={`${prop.path}`}
                            component={prop.component}
                            key={key}
                        />
                    )
                })}

            </Switch>
        </div>
    );

}


const mapStateToProps = state => {
	return {
	    dashboard: state.dashboard,
	};
};

export default connect(mapStateToProps, '')(Dashboard);