import { connect } from 'react-redux'
import { Card, CardFooter, Col, Row } from 'reactstrap'

import { Link } from 'react-router-dom'

const HomeOutstandingItems = ({ overview }) => {
    return (
        <div>
            <h2 className='mb-0'>Current Items Needed</h2>
            <Card className='card-no-shadow'>

                <CardFooter>
                    <Row>
                        <Col md={4}>
                            <p className='text-s mb-0 text-uppercase font-weight-bold'>
                                <Link className="text-dark"  to={`/${overview.matter.id}/dashboard/files`}>Files</Link>
                            </p>
                        </Col>

                        <Col md={8}>
                            <ul className='pl-0 ml-0 mb-0'>
                                {overview.progressDocuments.length ? overview.progressDocuments.map((s, i) => (
                                    <Link key={i}  to={`/${overview.matter.id}/dashboard/files`}>
                                        <li style={{listStyle: 'none'}}>
                                            <p className='text-sm mb-0 text-dark font-weight-bold'>
                                                {
                                                    s.status === 'approved' ? <i className="fas fa-check-circle text-success mr-2 d-inline-block" style={{width: 22}} /> : 
                                                    s.status === 'pending' ? <i className="fas fa-info-circle text-info mr-2 d-inline-block" style={{width: 22}} /> :
                                                    <i className="fas fa-times-circle text-danger mr-2 d-inline-block" style={{width: 22}} />
                                                }
                                                {s.matter_document_name}
                                                {s.status === 'pending' ? <small> (in review)</small> : ''}
                                            </p>
                                        </li>
                                    </Link>
                                )) : (
                                    <li style={{listStyle: 'none'}}>
                                          <p className='text-sm mb-0 text-dark font-weight-bold'>
                                            <i className="fas fa-check text-success mr-2 d-inline-block" style={{width: 22}} /> All Set!
                                        </p>
                                    </li>
                                )}
                            </ul>
                        </Col>
                    </Row>    
                </CardFooter>

                <CardFooter>
                    <Row>
                        <Col md={4}>
                            <p className='text-s mb-0 text-uppercase font-weight-bold'>
                                <Link className="text-dark"  to={`/${overview.matter.id}/dashboard/information`}>Forms</Link>
                            </p>
                        </Col>
                        
                        <Col md={8}>
                            <ul className='pl-0 ml-0 mb-0'>
                                {overview.progressForms.length ? overview.progressForms.map((s, i) => (
                                    <Link key={i}  to={`/${overview.matter.id}/dashboard/information`}>
                                        <li style={{listStyle: 'none'}}>
                                            <p className='text-sm mb-0 text-dark font-weight-bold'>
                                                {s.completion_percent >= 99.8 ? <i className="fas fa-check-circle text-success mr-2 d-inline-block" style={{width: 22}} /> : <i className="fas fa-times-circle text-danger mr-2 d-inline-block" style={{width: 22}} />}
                                                {s.form_name}
                                            </p>
                                        </li>
                                    </Link>
                                )) : (
                                    <li style={{listStyle: 'none'}}>
                                          <p className='text-sm mb-0 text-dark font-weight-bold'>
                                            <i className="fas fa-check-circle text-success mr-2 d-inline-block" style={{width: 22}} /> All Set!
                                        </p>
                                    </li>
                                )}
                            </ul>
                        </Col>
                    </Row>
                </CardFooter>

                <CardFooter>
                    <Row>
                        <Col md={4}>
                            <p className='text-s mb-0 text-uppercase font-weight-bold'>
                                <Link className="text-dark"  to={`/${overview.matter.id}/dashboard/signatures`}>Signatures</Link>
                            </p>
                        </Col>
                        <Col md={8}>
                            <ul className='pl-0 ml-0 mb-0'>
                                {overview.progressSignatures.length ? overview.progressSignatures.map((s, i) => (
                                    <Link key={i}  to={`/${overview.matter.id}/dashboard/signatures`}>
                                         <li style={{listStyle: 'none'}}>
                                            <p className='text-sm mb-0 text-dark font-weight-bold'>
                                                {s.status === 'signed' ? <i className="fas fa-check-circle text-success mr-2 d-inline-block" style={{width: 22}} /> : <i className="fas fa-times-circle text-danger mr-2 d-inline-block" style={{width: 22}} />}
                                                {s.template_name}
                                            </p>
                                        </li>
                                    </Link>
                                   
                                )) : (
                                    <li style={{listStyle: 'none'}}>
                                          <p className='text-sm mb-0 text-dark font-weight-bold'>
                                            <i className="fas fa-check-circle text-success mr-2 d-inline-block" style={{width: 22}} /> All Set!
                                        </p>
                                    </li>
                                )}
                                
                            </ul>
                        </Col>
                    </Row>
                </CardFooter>

                
            </Card>
        </div>
    )
}


const mapStateToProps = state => {
	return {
	    overview: state.dashboard.overview,
	};
};

export default connect(mapStateToProps, '')(HomeOutstandingItems);